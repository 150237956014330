* {
    font-family: Helvetica, Arial, sans-serif;
}

#nav-bar {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
    height: 5vh;
    align-items: center;
    background-color: #111D4A;
}

#nav-bar div {
    color: white;
    font-weight: bold;
}

.title {
    padding-left: 20px;
    font-weight: bolder;
}

#chat-nav {
    margin-left: auto;
    padding-right: 5px;
}

.container {
    display: flex;
    flex-direction: row;
    height: 95vh;
}

.drop-video {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    flex-grow: 1;
}

.video-container {
    background-color: black;
    color: #FFF8F0;
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 100%;
    flex-grow: 1;
}

.chat-container {
    background-color: #1e1e24;
    display: flex;
    flex-direction: column;
    flex-grow: 2;
}

.chat-container * {
    color: #FFF8F0;
}

.chat-messages, .chat-container h3 {
    text-align: center;
    
}

.chat-box {
    margin-top: auto;
    display: flex;
    height: 10%;
    border: 1px solid black;
}

.message-content {
    width: 75%;
    background-color: #1E1E24;
    color: white;
    border: 0px;
}

.send-message {
    flex-grow: 1;
    background-color: #111D4A;
    border: 0px;
}